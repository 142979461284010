import React from "react"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Container, Row, Col } from "react-bootstrap"
import SEO from 'components/seo'
import CardItem from "../components/Cards/CardItem"
import "./templates.scss"

const ListRealisationsTemplate = ({
  data, pageContext // this prop will be injected by the GraphQL query below.
}) => {
  const realisations = data.allMdx.edges;
  const pageData = data.mdx;
  const { currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = "/projets" + (currentPage - 1 === 1 ? "/" : "/" + (currentPage - 1).toString());
  const nextPage = "/projets/" + (currentPage + 1).toString()
  const items = [];
  realisations.map((real, i ) => {
    items.push({
      title: real.node.frontmatter.title,
      url: real.node.fields.slug,
      fluid : 
        real.node.frontmatter.image && 
        real.node.frontmatter.image.childImageSharp.fluid,
      categorie: real.node.frontmatter.categorie,
    });
    return null;
  });

  return (
    <>
      <SEO title={pageData.frontmatter.title} description={pageData.excerpt}/>
      <Container fluid className="container">
        <Row className="justify-content-md-center">
          <Col md="12">
            <MDXRenderer className="pages-content">
              {pageData.body}
            </MDXRenderer>
          </Col>
            {items.map((item, i) => (
              <Col key={i} md="4" lg="3">
                <CardItem item={item} />
              </Col>
            ))}
          <Col sm="6">
            {!isFirst && (
              <Link to={prevPage} rel="prev">
                ← Page précédente
              </Link>
            )}
          </Col>
          <Col sm="6" style={{textAlign: "right"}} >
            {!isLast && (
              <Link to={nextPage} rel="next">
                Page suivante →
              </Link>
            )}
          </Col>
        </Row>
      </Container>
    </>
  )
};

export default ListRealisationsTemplate;

export const pageQuery = graphql`
  query realisationsQuery($skip: Int!, $limit: Int!) {
    mdx(fields: { slug: { eq: "/projets" } }){
      body
      excerpt
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: {frontmatter: {type: {eq: "projets"}, image: {absolutePath: {ne: null}}}}
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            image {
              childImageSharp {
                fluid(maxWidth: 315, maxHeight: 315) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
            title
            categorie
          }
        }
      }
    }
  }
`